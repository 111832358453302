import React from 'react';
import styles from "./layoutPlaceholder.module.scss";
import SidebarUiPlaceholder from "../Sidebar/SidebarUiPlaceholder";
import { useLocation } from 'react-router-dom';

const LayoutPlaceholder = () => {
    const location = useLocation();
    const isDocumentsRoute = location?.pathname === "/documents";
    return (
        <div className={styles.appPlaceholder}>
            {
                !isDocumentsRoute && (
                    <div className={styles.sidebarPl}>
                        <SidebarUiPlaceholder/>
                    </div>
                )
            }
            <div className={styles.headerPl}/>
            <div className={styles.contentPl} />
        </div>
    );
}

export default LayoutPlaceholder;
