import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {classNames, classNames as cx} from 'primereact/utils';
import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';

import {LabelValue} from '../../LabelValue/LabelValue';
import {fieldsMaxLength} from '../../../config/fields';
import styles from '../styles.module.scss';
import {IFormProps, TDataItem} from '../types';

import Checkboxes from './Checkboxes';
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {ConfirmDialog} from "primereact/confirmdialog";

const Form: FC<IFormProps> = ({
  fieldsConfig,
  onSubmit,
  addressGroupFields,
  negativeValueWarning,
  isSupposeExists,
  setSuppose,
  captureFirstRecipientEmailAfterEmailFieldOnBlur,
  form,
  active,
  total,
  setFormStep,
  isPublic,
  showSelectTemplate,
}) => {
  const [taxableYearWarning, setTaxableYearWarning] = useState(false);
  const hiddenFormButtonRef: React.RefObject<HTMLButtonElement> = useRef(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    register,
  } = form;
  const countryValue = watch('country');
  const isUSSelected = countryValue === 'United States';
  const isSecondStep = active === 2;
  const backToFormFirstStep = () => {
    setFormStep((prev) => {
      return (
          { ...prev, active: 1 }
      )
    });
  }

  const onSubmitHandler = (passCheck: boolean) => {
    if (!passCheck && active === 2) {
      const dateOfTransfer = watch('date_of_transfer');
      const date = new Date(dateOfTransfer as string);
      const dateOfTransferYear = date.getFullYear().toString();
      const taxableYear = watch('taxable_year');
      if (dateOfTransferYear !== taxableYear) {
        setTaxableYearWarning(true);
        return;
      }
    }
    if (hiddenFormButtonRef.current) {
      hiddenFormButtonRef.current.click();
    }
  };

  const dateRanges = useMemo(() => {
    const minDate = new Date();
    const minDateValue = 21;
    minDate.setDate(minDate.getDate() - minDateValue);
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1);
    return {
      minDate,
      maxDate,
    }
  }, []);
  const { minDate, maxDate } = dateRanges;

  const getFormErrorMessage = (name: string) => {
    return (
        errors[name] && (
            <small className={cx('p-error', styles.errorMessage)}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-expect-error */}
              {errors[name].message}
            </small>
        )
    );
  };

  const toolTipErrorHolder = useCallback(
    (fieldName: string) => {
      return (
        <>
          <span data-name={fieldName}>{getFormErrorMessage(fieldName)}</span>
        </>
      );
    },
    [getFormErrorMessage],
  );

  const currentStepFieldsConfig = useMemo(() => {
    return fieldsConfig.filter((item) => item.step === active);
  }, [fieldsConfig, active]);

  // // TODO Review the solution
  // useEffect(() => {
  //   const firstErrorKey = Object.keys(errors)[0];
  //   if (firstErrorKey) {
  //     const dataNameKey = `[data-name=${firstErrorKey}]`;
  //     const errorElement = document.querySelector(dataNameKey);
  //     if (errorElement) {
  //       errorElement.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }
  // }, [JSON.stringify(errors)]);

  return (
    <>
      <form
        className={cx(styles.formWrapper)}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete={`off-${Math.random().toString(36).substring(7)}`}
      >
        <div className={styles.scrollWrapper}>
          {
            showSelectTemplate && !isSecondStep && (
                  <Checkboxes
                      isSupposeExists={isSupposeExists}
                      setSuppose={setSuppose}
                  />
              )
          }
          <div className={styles.formFields}>
            {fieldsConfig &&
                currentStepFieldsConfig.map((item: TDataItem) => {
                  const {
                    comment,
                    configName: fieldName,
                    configType,
                    label,
                    options,
                    configGroup,
                    required,
                    readOnly,
                  }: TDataItem = item;
                const isCurrencyField =
                  fieldName === 'price_per_share' || fieldName === 'price_paid';
                const isNumberOfSharesField = fieldName === 'number_of_shares';
                const numberOfSharesMaxValue = 100000000;
                const isCountryField = fieldName === 'country';
                const readOnlyFieldProps = readOnly ? {
                  readOnly: true,
                  disabled: true,
                } : {};
                if (
                  configGroup &&
                  Object.prototype.hasOwnProperty.call(
                    addressGroupFields,
                    configGroup,
                  )
                ) {
                  return (
                    <div
                      className={styles.multiGridWrapper}
                      key={`${fieldName}${configType}`}
                    >
                      {(addressGroupFields[configGroup] || []).map(
                        (item, index) => {
                          const {
                            comment,
                            configName: groupedFieldName,
                            label: groupedLabel,
                            required: groupedRequired,
                            configType: groupedConfigType,
                            options: groupedOptions,
                          } = item;
                          return (
                            <div
                              key={groupedLabel}
                              className={
                                [
                                  styles.cityCol,
                                  styles.stateCol,
                                  styles.zipCol,
                                ][index]
                              }
                            >
                              {groupedConfigType === 'ENUM' && isUSSelected ? (
                                <LabelValue
                                  label={groupedLabel}
                                  required={true}
                                  fieldName={groupedFieldName}
                                  comment={comment}
                                >
                                  <Controller
                                    name={groupedFieldName}
                                    control={control}
                                    render={({ field }) => {
                                      return (
                                        <Dropdown
                                          id={field.name}
                                          value={field.value}
                                          onChange={(e) => {
                                            const newValue = e.value;
                                            field.onChange(newValue);
                                          }}
                                          options={groupedOptions || []}
                                          style={{ width: '100%' }}
                                          {...readOnlyFieldProps}
                                        />
                                      );
                                    }}
                                  />
                                  {toolTipErrorHolder(
                                    groupedFieldName,
                                  )}
                                </LabelValue>
                              ) : (
                                <LabelValue
                                  label={groupedLabel}
                                  required={groupedRequired}
                                  fieldName={groupedFieldName}
                                  comment={comment}
                                >
                                  <Controller
                                    name={groupedFieldName}
                                    control={control}
                                    render={({ field }) => {
                                      return (
                                        <InputText
                                          {...field}
                                          value={field?.value as string}
                                          readOnly={readOnly}
                                          maxLength={fieldsMaxLength.name}
                                          style={{ width: '100%' }}
                                          type="text"
                                          autoComplete={`off-${Math.random()
                                            .toString(36)
                                            .substring(7)}`}
                                          {...readOnlyFieldProps}
                                        />
                                      );
                                    }}
                                  />
                                  {toolTipErrorHolder(
                                    groupedFieldName,
                                  )}
                                </LabelValue>
                              )}
                            </div>
                          );
                        },
                      )}
                    </div>
                  );
                }

                switch (configType) {
                  case 'STRING':
                  case 'SSN':
                  case 'EMAIL':
                    return (
                      <div key={fieldName}>
                        <LabelValue
                            label={label}
                            required={required}
                            fieldName={fieldName}
                            comment={comment}
                        >
                          <Controller
                            name={fieldName}
                            control={control}
                            render={({ field }) => {
                              return (
                                <InputText
                                  {...field}
                                  value={field.value as string}
                                  readOnly={readOnly}
                                  maxLength={fieldsMaxLength.name}
                                  style={{ width: '100%' }}
                                  type="text"
                                  autoComplete={`off-${Math.random()
                                    .toString(36)
                                    .substring(7)}`}
                                  onBlur={
                                    (fieldName === 'firstRecipientEmail' && isPublic)
                                      ? captureFirstRecipientEmailAfterEmailFieldOnBlur
                                      : () => null
                                  }
                                  {...readOnlyFieldProps}
                                />
                              );
                            }}
                          />
                          {toolTipErrorHolder(fieldName)}
                        </LabelValue>
                      </div>
                    );
                  case 'NUMERIC':
                  case 'DECIMAL':
                    return (
                      <div key={fieldName}>
                        <LabelValue
                            label={label}
                            required={required}
                            fieldName={fieldName}
                            comment={comment}
                        >
                          <Controller
                            name={fieldName}
                            control={control}
                            render={({ field }) => {
                              return (
                                <InputNumber
                                  {...field}
                                  value={field.value as number}
                                  {...readOnlyFieldProps}
                                  {...(isNumberOfSharesField
                                    ? {
                                        mode: 'decimal',
                                        max: numberOfSharesMaxValue,
                                      }
                                    : {})}
                                  {...(isCurrencyField
                                      ? {
                                        currency: 'USD',
                                        mode: 'currency',
                                        placeholder: '$',
                                        minFractionDigits: 2,
                                        maxFractionDigits: 10,
                                      }
                                      : {})}
                                  style={{ width: '100%' }}
                                  onChange={(e) => {
                                    const isSmaller = e?.value as number <= numberOfSharesMaxValue;
                                    if (isNumberOfSharesField && isSmaller) {
                                      field.onChange(e.value);
                                    }
                                    if (!isNumberOfSharesField) {
                                      field.onChange(e.value);
                                    }
                                  }}
                                />
                              );
                            }}
                          />
                          {toolTipErrorHolder(fieldName)}
                        </LabelValue>
                      </div>
                    );
                  case 'ENUM':
                    return (
                      <div key={fieldName}>
                        <LabelValue
                            label={label}
                            required={required}
                            fieldName={fieldName}
                            comment={comment}
                        >
                          <Controller
                            name={fieldName}
                            control={control}
                            render={({ field }) => {
                              return (
                                <Dropdown
                                  {...readOnlyFieldProps}
                                  id={field.name}
                                  value={field.value}
                                  onChange={(e) => {
                                    const newValue = e.value;
                                    const isNewValUS =
                                      newValue === 'United States';
                                    const stateEmptyVal = '';
                                    if (isCountryField) {
                                      setValue('state', stateEmptyVal);
                                      register('state', {
                                        required: isNewValUS,
                                      });
                                    }
                                    field.onChange(newValue);
                                  }}
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  options={options}
                                  style={{ width: '100%' }}
                                />
                              );
                            }}
                          />
                          {toolTipErrorHolder(fieldName)}
                        </LabelValue>
                      </div>
                    );
                  case 'DATE':
                    return (
                      <div key={fieldName}>
                        <LabelValue
                            label={label}
                            required={required}
                            fieldName={fieldName}
                            comment={comment}
                        >
                          <Controller
                            name={fieldName}
                            control={control}
                            render={({ field }) => {
                              return (
                                  <Calendar
                                      {...readOnlyFieldProps}
                                      id={field.name}
                                      value={field.value ? new Date(field.value) : undefined}
                                      showButtonBar
                                      onChange={(e) => field.onChange(e.value)}
                                      dateFormat="mm/dd/yy"
                                      style={{ width: '100%' }}
                                      minDate={minDate}
                                      maxDate={maxDate}
                                      placeholder="mm/dd/yy"
                                  />
                              )
                            }}
                          />
                          <i
                            className={classNames(
                              'pi pi-calendar',
                              styles.calendarIcon,
                            )}
                          />
                          {toolTipErrorHolder(fieldName)}
                        </LabelValue>
                      </div>
                    );
                  case 'TEXTAREA':
                    return (
                      <div key={fieldName}>
                        <LabelValue
                            label={label}
                            required={required}
                            fieldName={fieldName}
                            comment={comment}
                        >
                          <Controller
                            name={fieldName}
                            control={control}
                            render={({ field }) => {
                              return (
                                <InputTextarea
                                  {...field}
                                  {...readOnlyFieldProps}
                                  value={field.value as string}
                                  className={styles.textArea}
                                  maxLength={250}
                                  style={{ width: '100%' }}
                                />
                              );
                            }}
                          />
                          {toolTipErrorHolder(fieldName)}
                        </LabelValue>
                      </div>
                    );
                }
              })}
          </div>
          <button
            type="submit"
            ref={hiddenFormButtonRef}
            style={{ opacity: 0, pointerEvents: 'none' }}
          ></button>
        </div>
      </form>
      {negativeValueWarning && (
        <p className={styles.negativeValueWarning}>
          "Price Paid per Share" cannot be larger than "Price per Share".
        </p>
      )}
      <div className={styles.formFooter}>
        <div className={styles.formSteps}>
          <div className={classNames(styles.stepWidget, {
            [styles.fill]: isSecondStep,
          })}>
            <span className={styles.half}>
              <span/>
            </span>
            <span className={classNames(styles.half, styles.secondHalf)}>
              <span/>
            </span>
            <p>{`${active}/${total}`}</p>
          </div>
          <span className={styles.stepInfo}>
            {
              isSecondStep ? 'Equity info' : 'Personal info'
            }
          </span>
        </div>
        <div className={classNames(styles.formButtons)}>
          {
              isSecondStep && (
                  <ButtonPrimary
                      label="Back"
                      styleType="secondaryOutline"
                      type="button"
                      onClick={backToFormFirstStep}
                  />
              )
          }
          <ButtonPrimary
              type="button"
              onClick={() => onSubmitHandler(false)}
              label="Next"
          />
        </div>
      </div>
      <ConfirmDialog
          visible={taxableYearWarning}
          onHide={
            () => setTaxableYearWarning(false)
          }
          message="The taxable year you provided is different from the year of your grant.  Are you sure this is correct?"
          header="Warning"
          icon="pi pi-check"
          rejectLabel="Go back and fix"
          acceptLabel="Proceed"
          accept={() => onSubmitHandler(true)}
      />
    </>
  );
};

export default Form;
