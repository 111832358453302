import React, {FC, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import styles from './dashboard.module.scss';
import ButtonPrimary from "../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {useQuery} from "@tanstack/react-query";
import {requests} from "../../../../services/http-common";
import {Dropdown} from "primereact/dropdown";
import ReactPaginate from "react-paginate";
import sharedStyles from "../../../../assets/sharedStyles.module.scss";
import Loading from "../../../../components/Loading/Loading";
import {TFile83bData} from "../../../../@types/global";
import FileItem from "./FileItem.tsx/FileItem";
import usePagination from "../../../../hooks/usePagination";
import {perPageOptions} from "../../../../config/config";

interface TDashboardProps {
    companyId: number;
    userId: number;
    filingCreationEnabled: boolean;
}

const Dashboard: FC<TDashboardProps> = ({ companyId, userId, filingCreationEnabled }) => {
    const { params, changePage, changePerPage } = usePagination();
    const { page, size, sort} = params;

    const navigate = useNavigate();

    const searchUrl = useMemo(() => {
        const userIdStr = `&userId=${userId}`;
        const pageStr = `page=${page}&size=${size}`;
        const searchUrl = `${pageStr}${userIdStr}`;
        return `?${searchUrl}`;
    }, [page, size, sort]);

    const {
        data: filesData,
        isLoading: filesDataLoading,
    } = useQuery({
        queryKey: ['users', page, size, sort.join(''), companyId],
        queryFn: async () => {
            return await requests.get(
                `/api/companies/${companyId}/file83b${searchUrl}`
            );
        },
        staleTime: Infinity,
        gcTime: 0,
        retry: true,
    });
    const totalElements = filesData?.totalElements || 0;
    const files = filesData?.content || [];
    const pageCount = Math.ceil(totalElements / size);
    const tableLoading = filesDataLoading;

    const goToGeneration = () => {
        navigate('/filing/file-83b');
    }
  return (
    <div className={styles.dashboard}>
        <div className={styles.topRow}>
            {
                filingCreationEnabled && (
                    <ButtonPrimary
                        label="Add new filing"
                        iconClassName="bi-plus"
                        onClick={goToGeneration}
                    />
                )
            }
        </div>
        <div className={styles.filesGridWrapper}>
            <div className={styles.filesGrid}>
                {
                    tableLoading ? (
                        <div className={styles.loadingWrapper}>
                            <Loading className={styles.loadingCustomStyle}/>
                        </div>
                    ) : (
                        <>
                            {
                                files.length === 0 ? (
                                    <div className={styles.noRecords}>You don't have any 83(b) election filings yet.</div>
                                ) : (
                                    <>
                                        {
                                            files.map((item: TFile83bData) => {
                                                return (
                                                    <FileItem
                                                        data={item}
                                                        key={item.id}
                                                        companyId={companyId}
                                                    />
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                        </>
                    )
                }
            </div>
            {
                files.length > 0 && (
                    <div className={sharedStyles.paginationControl}>
                        <div className={sharedStyles.perPageRow}>
                            {`Showing ${(page) * size + 1} to ${((page) * size) + files.length} of ${totalElements} entries`}
                        </div>
                        <div className={sharedStyles.changeControl}>
                            <ReactPaginate
                                className={sharedStyles.paginationWrapper}
                                breakLabel="..."
                                nextLabel={<div className={sharedStyles.pItem}>{`>`}</div>}
                                onPageChange={({selected}) => changePage(selected)}
                                pageRangeDisplayed={size}
                                pageCount={pageCount}
                                previousLabel={<div className={sharedStyles.pItem}>{`<`}</div>}
                                renderOnZeroPageCount={null}
                                pageLabelBuilder={(page) => (
                                    <div className={sharedStyles.pItem}>{page}</div>
                                )}
                                activeClassName={sharedStyles.pItemActive}
                                forcePage={page}
                            />
                            <div className={sharedStyles.perPageHolder}>
                                <Dropdown
                                    options={perPageOptions}
                                    value={size}
                                    onChange={(e) => changePerPage(e.value)}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    </div>
  );
};

export default Dashboard;
