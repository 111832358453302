import React, {useState} from 'react';
import styles from './public83b.module.scss';
import DocumentGeneration from "../../components/DocumentGeneration/DocumentGeneration";
import Loading from "../../components/Loading/Loading";
import {useQuery} from "@tanstack/react-query";
import {TemplatesService} from "./TemplateService";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import {getYupDynamicScheme} from "../../components/DocumentGeneration/helpers/yupValidationHelpers";
const PublicFile83b = () => {
    const [isSupposeExists, setSuppose] = useState(false);

    const { data: initialInfo } = useQuery({
        queryKey: TemplatesService.fetchInitialInfo.queryKey,
        queryFn: () => TemplatesService.fetchInitialInfo.queryFn(),
    });
    const { templateCode, templateSpouseCode, price } = initialInfo || {};
    const template = isSupposeExists
        ? templateSpouseCode
        : templateCode;

    const { data: fieldsConfiguration, isLoading: requiredFieldsLoading } = useQuery({
        queryKey: [...TemplatesService.fetchTemplateConfiguration.queryKey, isSupposeExists],
        queryFn: () => TemplatesService.fetchTemplateConfiguration.queryFn(template),
        enabled: Boolean(templateCode) && Boolean(templateSpouseCode),
        gcTime: 0,
    });

    const { data: docAsHtml, isLoading: docAsHtmlLoading } = useQuery({
        queryKey: [...TemplatesService.exportDocAsHtml.queryKey, isSupposeExists],
        queryFn: () => TemplatesService.exportDocAsHtml.queryFn(template),
        enabled: Boolean(templateCode) && Boolean(templateSpouseCode),
        gcTime: 0,
    });
    const formSchema = fieldsConfiguration?.configs ? getYupDynamicScheme(fieldsConfiguration?.configs) : undefined;
    return (
        <div className={styles.public83b}>
            {
                ((!templateCode || !templateSpouseCode)) ? (
                    <div className={styles.loadingWrapper}>
                        <Loading horizontalSpacing={250} />
                    </div>
                ) : (
                    <>
                        <PublicHeader />
                        <DocumentGeneration
                            isSupposeExists={isSupposeExists}
                            setSuppose={setSuppose}
                            template={template}
                            data={fieldsConfiguration}
                            requiredFieldsLoading={requiredFieldsLoading}
                            docAsHtml={docAsHtml}
                            docAsHtmlLoading={docAsHtmlLoading}
                            isPublic
                            price={price}
                            showSelectTemplate={Boolean(templateSpouseCode)}
                            formSchema={formSchema}
                        />
                    </>
                )
            }
        </div>
    )
}

export default PublicFile83b;
